<template>
  <div>
    <v-toolbar class="secondary" dense style="color:white;font-weight:bold">
      Debit Report
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <download-excel
          :data="statementlist"
          name="Last_Debit.xls"
          :fields="fields"
          style="height:30px;margin-top:9px;margin-right:10px"
        >
          <v-btn class="success" small>Export</v-btn>
        </download-excel>
        <v-btn @click="fetchstatement" icon>
          <v-icon style="margin-top:0px" color="white">refresh</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table :headers="headers" :items="statementlist" :loading="loading" hide-actions>
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>

      <template slot="items" slot-scope="props">
        <td>
          {{
          new Date(parseInt(props.item.date))
          | moment("DD-MM-YYYY, h:mm:ss a")
          }}
        </td>
        <td>{{ props.item.tid }}</td>
        <td>{{ props.item.username }}</td>
        <td>{{ props.item.amount }}</td>
        <td>{{ props.item.total }}</td>
        <td>{{ props.item.credittype }}</td>
        <td>{{ props.item.paymenttype }}</td>
        <td v-if="showRemark">{{ props.item.creditusername }}</td>
      </template>
      <template slot="footer">
                    <tr>
                        <td colspan="3" style="text-align: right; font-weight: bold;">Grand Total:</td>
                        <td>{{ grandTotal }}</td>
                    </tr>
                </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      date1: "",
      menu1: false,
      date2: "",
      menu2: false,
      loading: false,
      headers: [
        { text: "Created Date", value: "date" },
        { text: "Pay Transfer ID", value: "tid" },
        { text: "Username", value: "username" },
        { text: "Amount", value: "amount" },
        { text: "Current Amount", value: "total" },
        { text: "Credit Type", value: "credittype" },
        { text: "Payment Type", value: "paymenttype" },
       
      ],
      fields: {
        "Created Date": "date",
        "Pay Transfer ID": "tid",
        Username: "username",
        Amount: "amount",
        "Current Amount": "total",
        "Credit Type": "credittype",
        "Payment Type": "paymenttype",
        "Credit Username":"creditusername"
      },
      statementlist: []
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },  showRemark() {
    debugger;
      let vm = this;
    return vm.user.Type == "Distributor" || vm.user.Type == "MasterDistributor"; // or whatever condition you need
  },
    grandTotal() {
            return this.statementlist.reduce((total, item) => total + item.amount, 0);
        },
  },
  methods: {
    showheaders() {
    debugger;
    if(this.showRemark)
    {this.headers = this.headers.concat({ text: "Credit Username", value: "creditusername" });}
    else{ this.headers;}
    return  this.headers;
  },
    fetchstatement() {
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc = { PinNo: vm.user.pin, MobileNo: vm.user.phone };
      let url = vm.$root.loginType === "dmr" ? "DebitListDMR" : "DebitList";
      vm.$store
        .dispatch("axios", {
          url: url,
          data: doc
        })
        .then(function(res) {
          if (res.data.Data) {
            vm.pushstatement(res.data.Data);
          }
        })
        .catch(function(error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn"
          });
        });
    },
    pushstatement(datas) {
      let vm = this;
      vm.statementlist = [];
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
        let doc = {
          date: data.CreatedDate.replace("/Date(", "").replace(")/", ""),
          tid: data.RechargeId,
          username: data.UserName,
          amount: data.Amount,
          total: data.CurrentAmount,
          paymenttype: data.PaymentTypeName,
          credittype: data.CreditTypeName,
          creditusername : data.CreditUserName,
        };
        vm.statementlist.push(doc);
      }
      vm.loading = false;
    }
  },
  mounted() {
    let vm = this;
    vm.showheaders();
    vm.fetchstatement();
  }
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
