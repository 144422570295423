<template>
  <div>
    <v-toolbar class="secondary" dense style="color:white;font-weight:bold">
      Debit Report
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <!-- <v-text-field
        label="Seach"
        >
          </v-text-field> -->
        <download-excel
          :data="statementlist"
          name="Transfer_Debit.xls"
          :fields="fields"
          style="height:30px;margin-top:9px;margin-right:10px"
        >
          <v-btn class="success" small>Export</v-btn>
        </download-excel>
        <v-menu
          :close-on-content-click="false"
          v-model="menu1"
          lazy
          transition="scale-transition"
          offset-y
          style="float:right"
        >
          <input
            type="text"
            slot="activator"
            style="color:black"
            v-model="date1"
            readonly
            class="search"
            placeholder="From Date"
          />
          <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
        </v-menu>
        <v-menu
          :close-on-content-click="false"
          v-model="menu2"
          lazy
          transition="scale-transition"
          offset-y
          style="float:right"
        >
          <input
            type="text"
            slot="activator"
            style="color:black"
            v-model="date2"
            readonly
            class="search"
            placeholder="To Date"
          />
          <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
        </v-menu>

        <v-btn
          class="success searchbtn"
          style="height:34px;margin-left:10px"
          @click="fetchstatement"
        >Search</v-btn>      </v-toolbar-items>
    </v-toolbar>
    <v-data-table :headers="headers" :items="statementlist" :loading="loading" hide-actions>
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template slot="items" slot-scope="props">
        <td>
          {{props.item.date
          }}
        </td>
        <td>{{ props.item.tid }}</td>
        <td>{{ props.item.username }}</td>
        <td>{{ props.item.amount }}</td>
        <td>{{ props.item.total }}</td>
        <td>{{ props.item.credittype }}</td>
        <td>{{ props.item.paymenttype }}</td>
        <td>{{ props.item.creditusername }}</td>
      </template>
      <template slot="footer">
                    <tr>
                        <td colspan="3" style="text-align: right; font-weight: bold;">Grand Total:</td>
                        <td>{{ grandTotal }}</td>
                    </tr>
                </template>
  
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment"
export default {
  data() {
    return {
      modal: false,
      date1: new Date().toISOString().substr(0, 10),
      menu1: false,
      date2: new Date().toISOString().substr(0, 10),
      menu2: false,
      loading: false,
      headers: [
        { text: "Created Date", value: "date" },
        { text: "Pay Transfer ID", value: "tid" },
        { text: "Username", value: "username" },
        { text: "Amount", value: "amount" },
        { text: "Current Amount", value: "total" },
        { text: "Credit Type", value: "credittype" },
        { text: "Payment Type", value: "paymenttype" },
        { text: "Credit Username", value: "creditusername" }
      ],
      fields: {
        "Created Date": "date",
        "Pay Transfer ID": "tid",
        Username: "username",
        Amount: "amount",
        "Current Amount": "total",
        "Credit Type": "credittype",
        "Payment Type": "paymenttype",
          "Credit Username":"creditusername"
      },
      statementlist: []
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }, grandTotal() {
            return this.statementlist.reduce((total, item) => total + item.amount, 0);
        },
  },
  methods: {
    fetchstatement() {
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        StartDate: vm.date1.split("-").join("/"),
        EndDate: vm.date2.split("-").join("/")
      };
      let url =
        vm.$root.loginType === "dmr"
          ? "MydownLinePaymentDateDMR"
          : "MydownLinePaymentDate";
      vm.$store
        .dispatch("axios", {
          data: doc,
          url: url
        })
        .then(function(res) {
          vm.loading = false;
          if (res.data.Data) {
            vm.pushstatement(res.data.Data);
          }
        })
        .catch(function(error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn"
          });
        });
    },
    pushstatement(datas) {
      let vm = this;
      vm.statementlist = [];
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i];
                let date = data.CreatedDate.replace("/Date(", "").replace(")/", "");
        date = moment(new Date(parseInt(date))).format("DD-MM-YYYY, h:mm:ss a")  
        let doc = {
          date: date,
          tid: data.PayTrfId,
          username: data.UserName,
          amount: data.Amount,
          total: data.CurrentAmount,
          paymenttype: data.PaymentTypeName,
          credittype: data.DebitTypeName,
          creditusername : data.CreditUserName,
        };
        vm.statementlist.push(doc);
      }
      vm.loading = false;
    }
  },
  mounted() {
    let vm = this;
    vm.fetchstatement();
  }
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
