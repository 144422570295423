<template>
  <div>
    <v-toolbar class="secondary"  dense style="color:white;font-weight:bold">
     My Load Wallet List

      <v-spacer></v-spacer>
      <v-toolbar-items>
        <download-excel
          :data="statementlist"
          name="LoadWalletReq.xls"
          :fields="fields"
          style="height:30px;margin-top:9px;margin-right:10px"
        >
          <v-btn class="success" small>Export</v-btn>
        </download-excel>
        <v-menu
          :close-on-content-click="false"
          v-model="menu1"
          lazy
          transition="scale-transition"
          offset-y
          style="float:right"
        >
          <input
            type="text"
            slot="activator"
            style="color:black"
            v-model="date1"
            readonly
            class="search"
            placeholder="From Date"
          />
          <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
        </v-menu>
        <v-menu
          :close-on-content-click="false"
          v-model="menu2"
          lazy
          transition="scale-transition"
          offset-y
          style="float:right"
        >
          <input
            type="text"
            slot="activator"
            style="color:black"
            v-model="date2"
            readonly
            class="search"
            placeholder="To Date"
          />
          <v-date-picker v-model="date2" @input="menu2 = false"></v-date-picker>
        </v-menu>

        <v-btn
          class="success searchbtn"
          style="height:34px;margin-left:10px"
          @click="fetchstatement"
        >
          Search</v-btn
        >
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="statementlist"
      :loading="loading"
      hide-actions
    >
      <v-progress-linear
        slot="progress"
        color="blue"
        indeterminate
      ></v-progress-linear>
      <template slot="items" slot-scope="props">
        
        <td>{{ props.item.Id }}</td>        
        <td>{{ props.item.OrderId }}</td>
        <td>{{ props.item.PGUTR }}</td>
        <td>{{ props.item.Amount }}</td>
        <td>
        <v-btn
          small
          :class="checkstatus(props.item.Status)"
          style="width:100%"
        >{{ checkrstatusname(props.item.Status) }}</v-btn>
      </td>
      <td>
          {{ new Date(parseInt(props.item.CreatedDate)) | moment("DD-MM-YYYY") }}
        </td>
        <td>{{ props.item.AgainstPaymentRequest }}</td>

        <td>
                <v-btn small style="width: 100%" v-if="props.item.Status === 0"
                  @click="openremark(props.item)" class="warning">Check Status</v-btn>
              </td>
       
      
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      date1: new Date().toISOString().substr(0, 10),
      menu1: false,
      date2: new Date().toISOString().substr(0, 10),
      menu2: false,
      loading: false,
      headers: [
      { text: "Id", value: "Id" },
        { text: "OrderId", value: "OrderId" },
        { text: "PGUTR", value: "PGUTR" },
        { text: "Amount", value: "Amount" },
        { text: "Status", value: "Status" },
        { text: "CreatedDate", value: "CreatedDate" },
        { text: "AgainstPaymentRequest", value: "AgainstPaymentRequest" },
     
        { text: "CheckStatus", value: "action", align: "center" },
      ],
      fields: {
        "Id": "Id",
        "OrderId": "OrderId",
        "PGUTR": "PGUTR",        
        "Amount": "Amount",
        "Status": "Status",
        "CreatedDate": "CreatedDate",
        "AgainstPaymentRequest": "AgainstPaymentRequest",       
      },

      statementlist: []
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    openremark(item) {
      debugger;
      let vm = this;
      vm.tid = item.OrderId;
      if (vm.tid == null) { return; }
      else {

        let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        OrderID: vm.tid,

      };

      vm.$store
        .dispatch("axiosdmrpost", {
          url: "PaymentStatusCheck",
          type: "sBPG",
          data: doc,
        })
        .then(function (res) {
          vm.loading = false;
          vm.$root.alert(res.data.Message);
        })
        .catch(function (error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn",
          });
        });
      }
    },
    checkstatus(status) {
   
      if (status == 2) return "success";
      if (status == 0) return "grey";
      if (status == 0) return "warning";
      if (status == 1) return "error";
      if (status == 3) return "error";
      return "primary";
    },
     checkrstatusname(status) {
      if (status == 2) return "success";
      if (status == 0) return "Pending";
      if (status == 0) return "warning";
      if (status == 1) return "Failed";
      if (status == 3) return "declined";
      return "primary";
  },
    
    fetchstatement() {
      debugger;
      let vm = this;
      if (vm.user.length == 0) return;
      vm.loading = true;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        StartDate: vm.date1,
        EndDate: vm.date2
      };
     

        vm.$store
        .dispatch("axiosdmrpost", {
          url: "AppSBPaymentReport",
          type: "sBPG",
          data: doc,
        })
        .then(function (res) {
          if(res.data.Status == "True")
          {
          vm.loading = false;
          vm.pushstatement(res.data.Data);}
          else{vm.loading = false;}
        })
        .catch(function (error) {
          vm.$store.dispatch("notify", {
            vm: vm,
            message: error,
            type: "warn",
          });
        });
    },
    pushstatement(datas) {

      let vm = this;
      vm.statementlist = [];
      for (let i = 0; i < datas.length; i++) {
        let doc = datas[i];
        let data = {
          Id: doc.Id,
          OrderId: doc.OrderId,
          PGUTR: doc.PGUTR,
          Amount: doc.Amount,
          Status: doc.Status,
          CreatedDate: doc.CreatedDate.replace("/Date(", "").replace(")/", ""),
          AgainstPaymentRequest: doc.AgainstPaymentRequest,
        };
        vm.statementlist.push(data);
      }
      vm.loading = false;
    }
  },
  mounted() {
    let vm = this;
    vm.fetchstatement();
  }
};
</script>

<style scoped>
.search {
  width: 150px;
  float: right;
  height: 34px;
  background: white;
  outline: none;
  margin-left: 20px;
  padding-left: 10px;
  font-size: 1em;
}
.searchbtn {
  margin-right: 15px;
  height: 34px;
  margin-left: 20px;
  float: right;
  margin-top: 0px;
}
</style>
