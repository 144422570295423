<template>
  <div style="width: 300px; margin: 0 auto">
    <!-- <div
      style="width: 100%; text-align: center; margin-top: 15px; height: 50px"
      class="headline"
    >
      IMPS
    </div> -->
    <v-autocomplete style="width: 300px; margin: 0 auto; height: 60px" :items="banklist" item-text="bank_name"
      item-value="bank_name" @change="onBankChange" label="Bank Name" v-model="bank" solo />

    <v-text-field type="text" v-model="ifsccode"  style="margin: 0 auto; height: 60px" label="Enter IFSC Code" solo />
    <!-- <v-text-field
      v-model="number"
      style="margin: 0 auto; height: 60px"
      label="Enter Mobile Number"
      solo
    /> -->
    <v-text-field v-model="accountnumber" @input="emitDataChange" style="margin: 0 auto; height: 60px"
      label="Enter Account Number" solo />
    <v-btn small class="primary" depressed round style="
        float: right;
        font-size: 0.8em;
        padding-left: 15px;
        padding-right: 15px;
      " @click="validate" :disabled="isvButtonDisabled">Fetch Name</v-btn> <br />
    <br />
    <v-text-field type="text" v-model="accountname" style="margin: 0 auto; height: 60px" label="Account Name" solo />

    <v-text-field v-model.number="amount" type="number" @input="onAmountChange" style="margin: 0 auto; height: 60px" label="Enter Amount"
      solo />
    <!-- <v-radio-group style="margin-left: 20px" row v-model="type">
              <v-radio label="IMPS" value="IMPS"></v-radio>
              <v-radio label="NEFT" value="NEFT"></v-radio>
            </v-radio-group> -->
    <v-text-field v-model="password" v-if="showPasswordField"  type="password" style="margin: 0 auto; height: 60px" label="Enter TPIN"
      solo />
    <div style="width: 100%; float: left; margin-bottom: 10px">
      <v-btn color="primary" style="width: 100%; margin: 0 auto" @click="MoneyTransfer"
        :disabled="isButtonDisabled">Submit</v-btn>
    </div>

    <v-dialog v-model="transfer" persistent width="400">
      <v-card>
        <v-card-title>Transfer Money</v-card-title>
        <v-divider></v-divider>
        <v-card-title style="padding-bottom: 0px">
          <div style="margin-bottom: 10px">
            <div>Name: {{ this.accountname }}</div>
            <div>Bank: {{ this.bank }}</div>
            <div>Acc No: {{ this.accountnumber }}</div>
            <div>IFSC Code: {{ this.ifsccode }}</div>
            <div>Amount: {{ this.amount }}</div>
          </div>
        </v-card-title>

        <v-card-actions>
          <v-btn flat color="success" @click="submit" :disabled="iscnfButtonDisabled">Confirm and pay</v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="error" @click="
            transfer = false;       
          isButtonDisabled  = false;
          ">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      number: "",
      accountname: "",
      accountnumber: "",
      ifsccode: "",
      receivernumber: "",
      remark: "",
      amount: "",
      password: "",
      banklist: [],
      bank: "",
      isButtonDisabled: false,
      isvButtonDisabled: false,
      showPasswordField: false,
      MaxAmountfortpin: "",
      transfer: false,
      iscnfButtonDisabled: false,


    };
  },
  props: {
    saccountDetail: {
      type: Object,
      required: true
    }
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    clear() {
      return this.$store.getters.clear;
    },
  },
  watch: {

    saccountDetail: {
      handler(newVal) {
        this.ifsccode = newVal.Ifsc.toUpperCase().trim();
        this.accountname = newVal.AccountName.trim();
        this.accountnumber = newVal.Accountno.trim();
        //  this.bank = newVal.BankName;
        this.bank = newVal.BankName.trim();


        this.$emit('data-changed', newVal);
        // console.log('saccountDetail changed:', newVal);
      },
      deep: true
    },
    clear() {
      this.cleardata();
    },

  },
  methods: {
    refresh() {
      this.$emit('refresh', '');
    },

    emitDataChange() {
      this.$emit('data-changed', this.accountnumber);
    },
    onBankChange() {
      let bank = this.banklist.find((item) => item.bank_name == this.bank_name);
      this.ifsccode = "";
      if (bank != null) {
        // this.ifsccode = bank.branch_ifsc;
      }
    },
    onAmountChange() {
      debugger;
      if (Number(this.MaxAmountfortpin) <= Number(this.amount)) {
        this.showPasswordField = true;
      }
      else {
        this.showPasswordField = false;
      }
    },

    fetchbank() {
      let vm = this;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
      };
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "Banklist",
          type: "dmrUrl",
          data: doc,
        })
        .then(function (res) {

          vm.banklist = res.data.data;
        })
        .catch(function (err) {
          vm.notification("error", err.message);
        });
    },

    fetchMaxAmountfortpin() {
      let vm = this;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
      };
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "GetAmountForPassword",
          type: "dmrRNP",
          data: doc,
        })
        .then(function (res) {

          vm.MaxAmountfortpin = res.data.Message;
        })
        .catch(function (err) {
          vm.notification("error", err.message);
        });
    },

    cleardata() {
      let vm = this;
      vm.number = "";
      vm.accountname = "";
      vm.accountnumber = "";
      vm.ifsccode = "";
      vm.receivernumber = "";
      vm.remark = "";
      vm.amount = "";
    },

    validate() {
      let vm = this;
      let BankD = this.banklist.find((item) => item.bank_name == vm.bank);
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "error",
        });
      if (BankD === undefined)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Bank Name Required!",
          type: "error",
        });

      if (vm.accountnumber == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Account Number Required",
          type: "error",
        });
      if (vm.ifsccode == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "IFSC Code Required",
          type: "error",
        });
        
      if (vm.ifsccode.length !== 11)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: " 11 digit IFSC Code Required",
          type: "error",
        });
      this.isvButtonDisabled = true;
      debugger;

      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        sndrMobileNo: vm.user.phone,
        BenName: vm.accountname.trim(),
        BenAccountNo: vm.accountnumber.trim(),
        BenIFSC: vm.ifsccode.toUpperCase(),
        BankName: BankD.bank_name.trim(),
        Mode: "IMPS",
        Password: vm.password
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "Validatebeneficiary",
          type: "dmrRNP",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          if (data.status === "True") {
            let message = data.message;
            let splitArray = data.message.split(':');
            vm.accountname = splitArray[1];
            vm.refresh();
            vm.$root.alert(message);
          }
          else {
            //vm.notification("error", data.Message);
            vm.$root.alert(data.message);
            vm.refresh();
          }
        })
        .catch(function (err) {
          vm.loader = false;
          //vm.notification("error", err.message);
          vm.$root.alert(err.message);
          vm.refresh();
        }).finally(() => {
          // Re-enable the button after the operation completes or fails
          this.isvButtonDisabled = false;
        });
    },

    MoneyTransfer() {

      let vm = this;
      let BankD = this.banklist.find((item) => item.bank_name == vm.bank);
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "warn",
        });
      if (BankD === undefined)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Bank Name Required!",
          type: "error",
        });


      if (vm.accountname == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Account Name Required",
          type: "error",
        });
      if (vm.accountnumber == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Account Number Required",
          type: "error",
        });
      if (vm.ifsccode == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "IFSC Code Required",
          type: "error",
        });
      if (vm.ifsccode.length !== 11)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "11 digit IFSC Code Required",
          type: "error",
        });

      if (vm.amount == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Transfer Amount Required",
          type: "error",
        });
      this.isButtonDisabled = true;
      this.transfer = true;
    },

    submit() {

      let vm = this;
      let BankD = this.banklist.find((item) => item.bank_name == vm.bank);
      if (vm.user.length == 0)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Authentication Required!",
          type: "warn",
        });
      if (BankD === undefined)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Bank Name Required!",
          type: "error",
        });


      if (vm.accountname == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Account Name Required",
          type: "error",
        });
      if (vm.accountnumber == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Account Number Required",
          type: "error",
        });
      if (vm.ifsccode == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "IFSC Code Required",
          type: "error",
        });
      if (vm.ifsccode.length !== 11)
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "11 digit IFSC Code Required",
          type: "error",
        });

      if (vm.amount == "")
        return vm.$store.dispatch("notify", {
          vm: vm,
          message: "Transfer Amount Required",
          type: "error",
        });
      this.iscnfButtonDisabled = true;
      let doc = {
        MobileNo: vm.user.phone,
        PinNo: vm.user.pin,
        Mobile: vm.user.phone,
        Name: vm.accountname.trim(),
        AccountNumber: vm.accountnumber.trim(),
        IFSC: vm.ifsccode,
        BankName: BankD.bank_name.trim(),
        Mode: "IMPS",
        Amount: vm.amount,
        password: vm.password
      };
      vm.loader = true;
      vm.$store
        .dispatch("axiosdmrpost", {
          url: "SendMoneyUsingBankAccount",
          type: "dmrRNP",
          data: doc,
        })
        .then(function (res) {
          vm.loader = false;
          let data = res.data;
          if (data.status === "True") {
            let message = data.message;
            vm.$root.alert(message);
            vm.refresh();
            this.transfer = false;
          }
          else {
            this.transfer = false;
            vm.refresh();
            //   vm.notification("error", data.Message);
          }

        })
        .catch(function (err) {
          vm.loader = false;
          this.transfer = false;
          vm.refresh();

          //   vm.notification("error", err.message);
        }).finally(() => {
          this.amount='';
          this.ifsccode='';
          this.accountname='';
          this.accountnumber='';
          this.bank='';
          this.emitDataChange();
          this.isButtonDisabled = false;
          this.iscnfButtonDisabled = false;
          // Re-enable the button after the operation completes or fails
          this.transfer = false;
          
        });

    },
    initDetails() {

      this.fetchbank();
      this.fetchMaxAmountfortpin();
    },
  },
  async mounted() {
    let vm = this;
    vm.fetchbank();
    vm.fetchMaxAmountfortpin();

    // vm.paymentPop = true;
    // vm.transferList = await vm.transList();
  }
};
</script>

<style>
.leftdiv {
  width: 300px;
  float: left;
  margin-bottom: 10px;
}

.rightdiv {
  margin-top: 10px;
  float: left;
  height: 100px;
}
</style>
